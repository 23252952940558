import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Extlink from 'common/src/components/Link';
import Container from 'common/src/components/UI/Container';
import downloadfile from '../../contents/pdfs/Rapid Acceleration Partners (RAP) (H).pdf';
import ContactSection2 from '../../containers/Hosting/Contact2/Misc';
import Achievementimage from 'common/src/assets/image/hosting/news/CIO.jpg';
import founder1 from 'common/src/assets/image/hosting/Team/Raghu.jpg';
import founder2 from 'common/src/assets/image/hosting/Team/Baskar.jpg';
import MiscWrapper from './misc.style';
import { useEffect, useState } from 'react';

const MiscSection1 = ({
  name,
  sectionWrapper,
  row,
  row1,
  col,
  col2,
  title,
  description,
  centeritems,
  bold_description,
  topmargin,
  linktext,
  buttonbox,
  innerrow,
  centertext,
  center_small_text,
  margintop,
  centertopic,
}) => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Rapid Acceleration Partners Ranked Among CIOReview Magazine’s “Top 10 MostPromising Robotic Process AutomationSolution Providers for 2020” | Rapid Acceleration Partners"
          description="Rapid Acceleration Partners"
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CaseStudyPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MiscWrapper>
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row1}>
                  <Box {...innerrow}>
                    <Fade left cascade>
                      <Box>
                        <Box {...row}>
                          <Box {...col}>
                            <a href={downloadfile} target="_blank">
                              <img
                                src={Achievementimage}
                                style={{ maxWidth: '100%' }}
                                alt="cio review certificate"
                              />
                            </a>
                          </Box>
                        </Box>
                        <Heading
                          {...title}
                          content="Rapid Acceleration Partners Ranked Among CIOReview Magazine’s “Top 10 Most Promising Robotic Process Automation Solution Providers for 2020”"
                        />

                        <Text
                          {...description}
                          content="We are absolutely delighted to announce that Rapid Acceleration Partners (RAP) has been recognized as one of the "
                        />
                        <Text
                          {...bold_description}
                          content="“Top 10 Most Promising Robotic Process Automation Solution Providers for 2020” "
                        />
                        <Text
                          {...description}
                          content=" by CIOReview Magazine. This award is testimony to RAP’s commitment to making AI-enabled Robotic Process Automation (RPA) for Intelligent Automation solutions that empower our clients. We have worked across"
                        />
                        <Text
                          {...bold_description}
                          content="  6 industries, processing 7 million+ pieces of content, saving 70% of manual effort "
                        />
                        <Text {...description} content="and" />
                        <Text
                          {...bold_description}
                          content=" reducing operational costs by 75% "
                        />
                        <Text
                          {...description}
                          content="for clients worldwide. Read more about RAP’s award in CIOReview Magazine "
                        />

                        <Extlink href={downloadfile} {...linktext}>
                          here.
                        </Extlink>

                        <Text
                          {...description}
                          content=" (This link is only accessible from the US.) A big thank you to Analytics Insights Magazine for recognizing us and featuring us for 2020!"
                        />
                        <Text
                          {...topmargin}
                          content="We’d like to say a big thank you to CIOReview Magazine for recognizing RAP and featuring us for their top 10 for 2020! RAP would also like to say a huge thank you to our team. This phenomenal success would not have been possible without their dedication, hard work, and commitment to excellence. RAP also extends our gratitude to our customers and investors who put their faith in us. "
                        />
                        <Text
                          {...topmargin}
                          content="CIOReview is a leading technology magazine that is at the forefront of guiding enterprises through the continuously varying business environment with information about the solutions and services. It serves as a trustworthy knowledge source as well as a platform for the C-suite executives, industry experts, IT buyers, and other decision-makers to share their valuable insights about new technology trends in the market. CIOReview stands out with its learn-from-our-own-peers approach, enabling the senior management of a company to select from a wide range of choices available in the tech arena. CIOReview magazine bridges the gap between enterprise IT vendors and buyers by presenting the vetted content and community resources. "
                        />
                        <Text
                          {...topmargin}
                          content="“RAP was founded based on a vision of opening the door to hyperautomation for everyone, by democratizing AI and IPA. I am very proud of how far we’ve come in the last 3 years, from the early days with a few pilot projects to being recognized as a leading industry player in the RPA and IPA space. I’m excited for what the future holds for RAP and our clients in this exciting era of disruptive innovation that is fundamentally changing how companies work, shaping a new normal that hinges on Intelligent Automation as a driver of sustainable success and business continuity under adverse circumstances”  Raghuraman Ramamurthy, Founder & CEO, RAP"
                        />
                        <Text
                          {...topmargin}
                          content="“I’m absolutely delighted that RAP has been ranked among the Top 10 Most Promising Robotic Process Automation Solution Providers for 2020. At RAP we’re on a mission to empower companies worldwide with hyperautomation capabilities thanks to our IPA and RPA solutions. The future looks bright for this disruptive technology and RAP will play a strong role as a digital transformation partner for companies across verticals.” Baskar Agneeswaran, Founder & Director, RAP"
                        />
                      </Box>
                    </Fade>

                    {/* <Box {...row}>
                      <Box {...col2}>
                        <Fade left cascade>
                          <img
                            src={founder1}
                            style={{ width: '150px', display: 'block' }}
                          />
                          <Text
                            {...centertext}
                            content="Raghuraman Ramamurthy"
                          />
                          <Text
                            {...center_small_text}
                            content="Founder & CEO, RAP."
                          />
                          <Text
                            {...centeritems}
                            content="“RAP was founded with a vision to democratize AI and IPA for everyone, opening the door to the future of hyperautomation. It has been quite a ride since our early days with a handful of pilot projects to carving out a niche as a recognized player in the industry over the last 3 years. It is truly gratifying to see the hard work and long nights paying off. More than that, it is invigorating to see what the future holds for us and our clients in this new, exciting chapter of disruptive innovation that is changing how companies work.” Raghuraman Ramamurthy, Founder & CEO, RAP."
                          />
                        </Fade>
                      </Box>
                      <Box {...col2}>
                        <Fade right cascade>
                          <img
                            src={founder2}
                            style={{ width: '150px', display: 'block' }}
                          />
                          <Text {...centertext} content="Baskar Agneeswaran" />
                          <Text
                            {...center_small_text}
                            content="Founder & Director, RAP"
                          />
                          <Text
                            {...centeritems}
                            content="“I’m delighted that RAP has been recognized among the Top 10 Most Valuable Digital Transformation Companies in 2020. Automation is the touchstone of the future, and our mission is to empower companies worldwide with hyperautomation. The future of this disruptive technology is exciting and RAP will play a significant role as a digital transformation partner for companies worldwide.” Baskar Agneeswaran, Founder & Director, RAP"
                          />
                        </Fade>
                      </Box>
                    </Box> */}

                    {/* <Heading {...centertopic} content="About us" /> */}
                    <Text
                      {...topmargin}
                      content="RAP provides practical AI solutions for digital business transformation leveraging IPA, RPA and hyperautomation. With a view to democratize AI, RAP has developed disruptive, innovative products RAPFlow – a next-gen, full-lifecycle AI orchestration platform for building content intelligence solutions, and RAPBot – our RPA tool for end-to-end automation. When used in tandem they provide game-changing, end-to-end hyperautomation for processes – this enables greater productivity, capacity and efficiency while reducing operating costs. To date, RAP has worked with several companies across various industries helping them jump to lightspeed and rev up operational efficiency by automating repetitive processes. "
                    />

                    <Box {...row}>
                      <Box {...buttonbox}>
                        <a
                          href={downloadfile}
                          className="btn btn_tertiary"
                          target="_blank"
                        >
                          <span className="btn-text">View Article</span>
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </MiscWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

MiscSection1.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '100px',
    pb: ['0', '0', '50px', '50px', '50px'],
    id: 'misc_section',
  },
  row1: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  innerrow: {
    width: ['100%', '100%', '100%', '70%', '70%'],
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '100%', '100%'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '35px', '35px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'center',
    className: 'heading',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
    display: 'inline',
  },
  bold_description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    fontWeight: 'bold',
    mb: '10px',
    display: 'inline',
  },
  centeritems: {
    textAlign: 'justify',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  centertext: {
    textAlign: 'center',
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '8px',
  },
  center_small_text: {
    textAlign: 'center',
    fontSize: ['14px', '14px', '14px', '14px', '14px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  topmargin: {
    mt: '15px',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  linktext: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    color: 'blue',
  },
  buttonbox: {
    width: [1, 1, 0.2, 0.2, 0.2],
    flexBox: true,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    mt: '20px',
  },
  margintop: {
    mt: '50px',
  },
  centertopic: {
    textAlign: 'center',
  },
};

export default MiscSection1;
